var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-1"},[_c('validation-observer',{ref:"refFormObserver"},[_c('validation-provider',{attrs:{"name":"Image Upload","rules":_vm.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('b-form-group',[_c('label',{attrs:{"for":_vm.id}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('uploadImage.browsePicture'))+" "+_vm._s(_vm.x)+"x"+_vm._s(_vm.y)+" ")]),(_vm.required)?_c('p',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('global.mandatoryField')))])]):_vm._e()]),_c('b-form-file',{attrs:{"id":_vm.id,"placeholder":_vm.$t('uploadImage.uploadPictureHere'),"state":dirty ? (errors[0] ? false : (valid ? true : null)) : null,"accept":"image/*"},on:{"change":_vm.readFile},model:{value:(_vm.imageInput),callback:function ($$v) {_vm.imageInput=$$v},expression:"imageInput"}}),(_vm.src !== '' && _vm.cropped === '')?[_c('vue-cropper',{ref:"cropper",staticClass:"m-1",attrs:{"aspect-ratio":_vm.x / _vm.y,"src":_vm.src,"alt":"Source Image","drag-mode":"none","scalable":"","zoomable":false,"view-mode":2,"ready":function () {
                var contData = _vm.$refs.cropper.getContainerData();
                _vm.$refs.cropper.setCropBoxData({
                  height: contData.height,
                  width: contData.width,
                });
              }}}),_c('div',{on:{"click":_vm.cropImage}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","size":"sm","disabled":_vm.disabledBtn}},[_vm._v(" "+_vm._s(_vm.$t('uploadImage.crop'))+" ")])],1)]:_vm._e()],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }